button{
    color: #FFF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    line-height: 20px; /* 125% */
    font-size: 1rem;
    border: 0px;

    border-radius: 100px;

    cursor: pointer;
}

.btn-small{
    padding: 12px 16px;
}

.btn-medium{
    padding: 16px 32px;
}



.btn-whiteborder{
    background: none;
    border: solid 1px white;
    color: white;
}

.btn-red{
    background: var(--Renau);
    color: white;
}