#InfoContact{
    display: flex;
}

#InfoContact > div{
    width: 100%;
}

#InfoContact li{
    display: flex;
    flex-direction: column;
}

#InfoContact li.mt-16 p{
    margin-bottom: 8px;
    font-weight: bold;
}

#InfoContact li a{
    width: fit-content;
}

#InfoContact .compagnie{
    font-weight: bold;
}