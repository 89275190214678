#ListFamille .cards{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

#ListFamille .card{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: calc(25% - 15px);
    object-fit: cover;
    overflow: hidden;
    min-width: 250px;

    border-radius: 16px;

    aspect-ratio: 1 / 1;

    
}

#ListFamille .card p {
    position: absolute;
    color: #FFF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    bottom: 16px;

    width: 100%;
    text-align: center;

}

#ListFamille .card img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all ease-in 300ms;
}

#ListFamille .card:hover img{
    width: 120%;
}