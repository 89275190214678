#Newsletter{
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--Red10);

    padding: 64px 0px;
}

#Newsletter p {
    max-width: 580px;
    color: #161616;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
}

#Newsletter div{
    position: relative;
    width: 480px;
height: 52px;
}

#Newsletter div input{
    position: absolute;
    background-color: white;
    border-radius: 100px;
    width: 100%;
    height: 100%;
    padding-left: 24px;
}


#Newsletter div button{
    position: absolute;
    right: 0px;
}