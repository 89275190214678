footer{
    padding-top: 64px;
    border-top: solid 1px rgba(22, 22, 22, 0.05);
}

footer span{
    display: block;
    height: 1px;
    width: 100%;
    background: rgba(22, 22, 22, 0.05);
}

.FooterCoor{
    display: flex;
    flex-direction: column;
}

.FooterLink{
    display: flex;
    gap: 124px;
}

.FooterCoorLink{
    display: flex;
    justify-content: space-between;
}

.FooterLink ul{
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.CopyrightDroit{
    display: flex;
    justify-content: space-between;
    padding: 32px 0px;
}

.CopyrightDroit p {
    color: #161616;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.CopyrightDroit ul{
    display: flex;
    gap: 16px;
}

.CopyrightDroit a{
    color: #161616;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}