.mt-4{
    margin-top: 4px;
}

.mt-8{
    margin-top: 8px;
}

.mt-12{
    margin-top: 12px;
}

.mt-16{
    margin-top: 16px;
}

.mt-20{
    margin-top: 20px;
}

.mt-24{
    margin-top: 24px;
}
.mt-32{
    margin-top: 32px;
}

.mt-40{
    margin-top: 40px;
}

.mb-32{
    margin-bottom: 32px;
}

.mt-64{
    margin-top: 64px!important;
}

.mt-124{
    margin-top: 124px!important;
}

.my-64{
    margin-top: 64px;
    margin-bottom: 64px;
}



.mb-64{
    margin-bottom: 64px!important;
}

.mb-124{
    margin-bottom: 124px!important;
}


.mb-16{
    margin-bottom: 16px;
}