@import url(components/atomes/button.css);
@import url(components/atomes/margin.css);
@import url(components/atomes/container.css);
@import url(components/atomes/font.css);


@import url(components/general/nav.css);
@import url(components/general/footer.css);

@import url(components/index/HomeNumber.css);
@import url(components/index/Client.css);
@import url(components/index/Newsletter.css);
@import url(components/index/HeroBanner.css);
@import url(components/index/SavoirFaire.css);
@import url(components/index/Actualite.css);
@import url(components/index/Travail.css);
@import url(components/index/Temoignage.css);

@import url(components/famille/HeroBanner.css);
@import url(components/famille/ListFamily.css);

@import url(components/Contact/InfoContact.css);
@import url(components/Contact/Devis.css);




@font-face {
    font-family: 'Inter'; 
    font-style: normal;
    font-weight: 100; /* thin */
    src: url('../../Assets/Fonts/Inter/Inter-Thin.ttf') format('truetype'); 
    font-display: swap;
}

@font-face {
    font-family: 'Inter'; 
    font-style: normal;
    font-weight: 200; /* extra-light */
    src: url('../../Assets/Fonts/Inter/Inter-ExtraLight.ttf') format('truetype'); 
    font-display: swap;
}

@font-face {
    font-family: 'Inter'; 
    font-style: normal;
    font-weight: 300; /* light */
    src: url('../../Assets/Fonts/Inter/Inter-Light.ttf') format('truetype'); 
    font-display: swap;
}

@font-face {
    font-family: 'Inter'; 
    font-style: normal;
    font-weight: 400; /* regular */
    src: url('../../Assets/Fonts/Inter/Inter-Regular.ttf') format('truetype'); 
    font-display: swap;
}

@font-face {
    font-family: 'Inter'; 
    font-style: normal;
    font-weight: 500; /* medium */
    src: url('../../Assets/Fonts/Inter/Inter-Medium.ttf') format('truetype'); 
    font-display: swap;
}

@font-face {
    font-family: 'Inter'; 
    font-style: normal;
    font-weight: 600; /* semi-bold */
    src: url('../../Assets/Fonts/Inter/Inter-SemiBold.ttf') format('truetype'); 
    font-display: swap;
}

@font-face {
    font-family: 'Inter'; 
    font-style: normal;
    font-weight: 700; /* bold */
    src: url('../../Assets/Fonts/Inter/Inter-Bold.ttf') format('truetype'); 
    font-display: swap;
}

@font-face {
    font-family: 'Inter'; 
    font-style: normal;
    font-weight: 800; /* extra-bold */
    src: url('../../Assets/Fonts/Inter/Inter-ExtraBold.ttf') format('truetype'); 
    font-display: swap;
}

@font-face {
    font-family: 'Inter'; 
    font-style: normal;
    font-weight: 900; /* black */
    src: url('../../Assets/Fonts/Inter/Inter-Black.ttf') format('truetype'); 
    font-display: swap;
}

*{
    font-size: 16px;
    text-decoration: none;
    list-style: none; 
    margin: 0px;
    padding: 0px;
    border: 0px;
    outline: none;
    box-sizing: border-box;

    font-family: Inter;
    scroll-behavior: smooth;
}

:root{
    --Renau: #D30000;
    --Red10: #F9EDED;
}

body, html{
    width: 100vw;
    overflow-x: hidden;

}


a{
    color: #161616;
}



input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}



/* Masquer les contrôles du volume sur Chrome, Edge et Safari */
video::-webkit-media-controls-volume-slider,
video::-webkit-media-controls-mute-button {
    display: none !important;
}

/* Safari (macOS & iOS) - Désactive aussi l'affichage du bouton mute */
video::-webkit-media-controls {
    overflow: hidden !important;
}







#Admin{
    display: flex;
    flex-direction: column;
}

#Admin p{
    color: #161616;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
}

#Admin h1{
    color: #161616;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    margin: 124px auto 64px auto;
}

#Admin h4{
    color: #161616;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    margin-top: 64px;
    margin-bottom: 32px;
}



footer a{
    color: #161616;
}



#ContactForm{
    display: flex;
    flex-direction: column;

    gap: 20px;

}

#ContactForm div{
    display: flex;
    gap: 20px;
}

#ContactForm input, #ContactForm textarea{
    color: #161616;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    border-radius: 8px;
    border: 1px solid #161616;

    padding: 16px 24px;
    resize: none;
    width: 100%;
}

#ContactForm button{
    margin-top: 20px;
    margin-left: auto;
}







.left-translate{
    animation: scrollImagesgd 45s linear infinite;
}
.right-translate{
    animation: scrollImagesdg 45s linear infinite;
}


@keyframes scrollImagesgd {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(1.2%);
    }
}


@keyframes scrollImagesdg {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-101.2%);
    }
}



#CompagnyTeam .cards{
    display: flex;
    flex-wrap: wrap;
}

#CompagnyTeam a{
    width: 25%;
    max-height: 400px;
    overflow: hidden;
    position: relative;
}

#CompagnyTeam .card img{
    height: 100%;
    width: 121%;
    object-fit: cover;
}

#CompagnyTeam .card div{
    position: absolute;
    bottom: 24px;
    left: 24px;
    transform: translateY(80%);
    transition: all ease-in-out 300ms;
}

#CompagnyTeam a:hover .card div{
    transform: translateY(0px);
}   

.TeamerName{
    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.TeamerPoste{
    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
}

.TeamerPhrase{
    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 125% */
    margin-right: 24px;
}



#CompagnyNumber{
    background: var(--Renau);
    padding: 96px 0px;
}



#CompagnyNumber h3{
    color: white;
}

#CompagnyNumber .Wrapper div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

#CompagnyNumber .Number{
    color: #FFF;
    font-family: Inter;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -1.92px;
}

#CompagnyNumber .Descriptif{
    color: #FFF;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    max-width: 250px;

    text-align: center;
}



#CompagnyValue .Wrapper{
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
}

#CompagnyValue .Wrapper > div > div{
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--Renau);
    border-radius: 8px;
    width: 64px;
    height: 64px;
}

#CompagnyValue .Wrapper img{
    max-height: 32px;
    width: auto;
}

#CompagnyValue .Wrapper div{
    width: calc(33.33% - 22px);
}

#CompagnyValue .Wrapper .Title{
    margin-top: 16px;
    margin-bottom: 8px;
    color: #161616;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}


#CompagnyAbout .Information{
    display: flex;
    gap: 64px;
}

#CompagnyAbout .Information > div{
    width: 50%;
}

#CompagnyAbout .Information p{
    color: #161616;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    text-align: justify;
}

#CompagnyAbout .Information .Carrousel{
    border-radius: 16px;
    overflow: hidden;
}

#CompagnyAbout .Carrousel img{
    width: 33.3333333%;
    height: 100%;
    object-fit: cover;

    aspect-ratio: 1/1;
}


#Actualite .cards{
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    
}

#Actualite .card{
    border-radius: 16px;
    background: #FFF;
    /* Ombre droped */
    box-shadow: 0px 4px 40px -6px rgba(0, 0, 0, 0.13);

    width: calc(33.33% - 22px);
    overflow: hidden;

    
}


#Actualite .News{
    color: var(--Rouge-Renau, #D30000);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

#Actualite .Title{
    color: #161616;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 8px;
}

#Actualite .containerInfo{
    padding: 24px;
}

#Actualite .Descriptif{
    color: #161616;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
}

#Actualite a{
    display: block;
    color: var(--Rouge-Renau, #D30000);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-left: auto;
    width: fit-content;
    margin-top: 16px;
}






#FormProduit form{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

#FormProduit .ContainerProduit{
    display: flex;
    gap: 32px;
}

#FormProduit .ContainerProduit > div {
    width: 50%;
}

#FormProduit .ContainerProduit .information{
    display: flex;
    flex-direction: column;
}

#FormProduit .information p{
    color: #161616;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
}

#FormProduit form div{
    display: flex;
    gap: 20px;
}

#FormProduit form input, #FormProduit form textarea{
    padding: 16px 24px;
    border-radius: 8px;
    border: 1px solid #161616;


    width: 100%;
    resize: none;
}

#FormProduit form textarea{
    height: 130px;
}


#FormProduit form button{
    margin-left: auto;
}


#VideoFiche{
    width: 100vw;
    min-height: 100vh;
    position: relative;
    padding: 64px 0px;
}

#VideoFiche h3{
    color: white;
}

#VideoFiche img{
    position: absolute;
    top: 0px;
    width: 100vw;
    height: calc(100vh - 100px);
    z-index: -1;
    object-fit: cover;
}




#EssentialInfo input, #ProductInfo input, #ConveyorInfo input, #ConveyorInfo select{
    padding: 16px 24px;
    border-radius: 8px;
    border: 1px solid #161616;

    color: #161616;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.dropzone{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    border: solid 1px #161616;
    border-radius: 16px;
    aspect-ratio: 1/1;
    cursor: pointer;
}

.dropzone p{
    margin-top: 24px;
    color: #000;
text-align: center;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
max-width: 400px;
}

.containerdocuments{
    display: flex;
    flex-direction: column;
    gap: 20px;
}


.Productanwser{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.Productanwser p {
    font-size: 20px;
    font-weight: 600;
}

.Productanwser div{
    display: flex;
    gap: 20px;
}

.Productanwser input{
    width: 100%;
}

.Productanwser button{
    margin-left: auto;

}

.fileszone{
    border: solid 1px #161616;
    border-radius: 16px;
    width: 100%;
    height: 24%;

    padding: 24px;

    overflow-y: scroll;
}

.fileszone ul{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 12px;
}

.fileszone div{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
}

.fileszone svg{
    cursor: pointer;
}

.fileszone li{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.fileszone img{
    width: 20px;
}

.dragging{
    background-color: rgba(0, 0, 0, 0.13);
    border: dashed 1px #161616!important;
}



.dragging .a1{
    display: none;
}

.a2{
    display: none;
}

.dragging .a2{
    display: block;
}

.ProductForm{
    display: flex;
    gap: 40px;
}

.ProductForm > div{
    width: 50%;
}


#EssentialInfo div{
    display: flex;
    gap: 20px;
}


.ItemList{
    display: flex;
    gap: 20px;
}

.ItemList .active{
    background: var(--Renau);
    color: white;
}

.ItemList p{
    pointer-events: none;
}

.ItemList li{
    border-radius: 8px;
    border: 1px solid var(--Rouge-Renau, #D30000);
    background: #FFF;

    padding: 16px 24px;

    cursor: pointer;
    color: var(--Renau);

    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}


.PreviewConveyor{
    width: 100%;
    aspect-ratio: 1/1;

    border: solid 1px rgba(0, 0, 0, 0.13);
    border-radius: 16px;
}

.PreviewConveyor img{
    width: 100%;
    height: -webkit-fill-available;
    object-fit: cover;
    border-radius: 16px;
}




#CommentForm textarea{
    width: 100%;
    height: 200px;

    resize: none;

    border: solid 1px #161616;
    border-radius: 16px;

    color: #161616;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;

padding: 16px 24px;
margin-top: 20px;
}

#CommentForm button{
    margin-top: 24px;
    display: block;
    margin-left: auto;
    width: fit-content;
}

#myThreeJsScene{
    height: fit-content;
    z-index: -1;
    height: 100%;
    width: 100%;
}

#myThreeJsScene canvas{
    width: 100%;
    border-radius: 8px;
}




#CompagnyNumber .Wrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 96px;
}

#CompagnyNumber .Wrapper .NumberContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}






#FicheTechnique > div{
    display: flex;
    gap: 64px;
}



#FicheTechnique .Rendu{
    width: 400px;
}

#FicheFiles ul{
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    width: 100%;
}

#FicheFiles ul a{
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    padding: 8px 12px;
    background: var(--Renau);
    color: white;
}

.RedTitle{
    color: var(--Rouge-Renau, #D30000);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;

    padding: 16px 0px;
    margin-top: 16px;

    border-top: solid 1px rgba(0, 0, 0, 0.13);
}


#SavoirFaire ul li{
    list-style:disc;
    margin-left: 16px;
    color: #161616;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
}



#FicheTechnique tbody tr:nth-child(1), 
#FicheTechnique tbody tr:nth-child(3), 
#FicheTechnique tbody tr:nth-child(5), 
#FicheTechnique tbody tr:nth-child(7),
#FicheTechnique tbody tr:nth-child(9),
#FicheTechnique tbody tr:nth-child(11) {
    background: #F8F8F8;
}

#FicheTechnique tbody td, #FicheTechnique tbody th{
    padding: 8px;
    text-align: left;
}

#FicheTechnique table{
    width: 100%;
}


.FicheList li{
    margin-left: 32px;
    list-style: disc;
}


#ficheBande div{
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

#ficheBande img{
    max-width: 72px;
}


#FicheTechnique th{
    font-weight: 500;
}


#fichemoteur img{
    max-width: 64px;
}


#Temoignage .Menu{
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 96px;
    
}

#Temoignage .Menu span{
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 100px;
    background: #D9D9D9;
    cursor: pointer;
}

#Temoignage .Menu .active{
    width: 12px;
    height: 12px;
    background: #F96464;

}


#CompagnyAbout .Carrousel{
    position: relative;
}

#CompagnyAbout .Coulissant{
    display: flex;
    position: absolute;
    width: fit-content;
    width: 300%;
    height: 100%;

    transition: ease-in-out all 1000ms;
}


#FamilleHeroBanner.compagny{
    height: calc(100vh - 100px);
}



#FamilleHeroBanner .Carrousel{
    position: absolute;
    width: 100vw;
    height: 100%;
    z-index: -2;
}

#FamilleHeroBanner .Coulissant{
    display: flex;
    position: absolute;
    width: fit-content;
    width: 600%;
    height: 100%;

    transition: ease-in-out all 1000ms;
}

#FamilleHeroBanner .Coulissant img{
    position: static;
}



#BlackFilter{
    position: absolute;
    width: 100vw;
    height: 100%;
    background: rgba(0, 0, 0, 0.30);
    z-index: -1;
}



#SavoirFaire .Carrousel{
    position: relative;
    display: flex;
    overflow: hidden;
    height: 300px;

}


#SavoirFaire .Coulissant{
    display: flex;
    position: absolute;
    width: fit-content;
    width: 200%;
    height: 100%;

    transition: ease-in-out all 1000ms;
}


.NavFlag{
    display: flex;
    justify-content: center;
    width: 24px;
    height: 16px;
    overflow: hidden;
}

.NavFlag:hover{
    overflow: initial;
}

.containerFlag{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    background-color: white;
    height: fit-content;
    width: 100%;

    border-radius: 2px;

    padding-bottom: 4px;


}

.NavFlag img{
    cursor: pointer;
    width: 16px;
}


.containerslogan{
    display: flex;
    align-items: center;
    gap: 16px;
    position: absolute;

    top: 96px;
    width: 100%;
    justify-content: center;

   
}

.containerslogan p{
    font-size: 48px;
    color: white;
    font-weight: 500;
}

.containerslogan img{
    width: 96px;
}

.BlackFilter{
    width: -webkit-fill-available;
    min-height: -webkit-fill-available;
    position: absolute;

    background: rgba(0, 0, 0, 0.30);

    z-index: -1;
}



#Articles .Wrapper{
    display: flex;
    flex-wrap: wrap;
    column-gap: 64px;
}

#Articles .Wrapper a{
    display: flex;
    width: calc(50% - 32px);
    gap: 16px;
    border-top: solid 1px rgba(0, 0, 0, 0.13);
    border-bottom: solid 1px rgba(0, 0, 0, 0.13);
    padding: 32px 0px;
    margin-top: -1px;
}

#Articles .Wrapper .card-img{
    max-width: 220px;


}

#Articles .card-img img{
    width: 100%;
}

#Articles .card-title{
    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
}

#Articles .card-description{
    color: #161616;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    margin-top: 4px;
}


#Articles .card-sub{
    margin-top: 12px;
    color: var(--Rouge---Nol, #D30000);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}


#Article{
    display: flex;
    gap: 64px;
}

#Article .contenu{
    width: 60%;
}

#Article .contenu p{
    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
}

#Article aside a{
    color: var(--Renau);
    font-weight: 600;
}



#Actualite .cards img{
    width: 100%;
    height: 140px ;
}



.FooterLink .actualite a{
    display: block;
    white-space: nowrap;
    overflow: hidden;
    

}







#Technical-Conveyor{
    display: flex;
    gap: 27px;
}


#Product-Visual{
    display: flex;
    flex-direction: column;
    max-width: 866px;
    gap: 16px;
}


#Product-Visual #MainScene{
    width: 100%;
    height: 502px;
    max-height: 502px;
}


#Product-Visual #MainScene img, #Product-Visual #MainScene video, #Product-Visual #MainScene div{
    border-radius: 8px;
    object-fit: cover;
    width: 866px!important;
    height: 502px!important;
}

#Product-Visual #MainScene svg{
    position: absolute;
}


#Product-Visual #D3-Suggest, #Product-Visual #Video-Suggest{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

#Product-Visual #D3-Suggest svg, #Product-Visual #Video-Suggest svg{
    position: absolute;
    pointer-events: none;
    z-index: 1000;
}


#Video-Suggest::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2); /* Voile noir avec opacité de 10% */
    pointer-events: none; /* Permet d'interagir avec la vidéo en dessous */
    border-radius: 4px;
}

#Product-Visual .container-coulissant{
    height: 78px;
    width: 100%;
    overflow: hidden;
}

#Product-Visual .Coulissant{
    display: flex;
    align-items: center;
    gap: 16px;
    height: 100%;
    width: max-content;
    position: relative;

    transition: all ease-in-out 400ms;
}


#Product-Visual .Coulissant img, #Product-Visual .Coulissant video{
    width: 140px;
    height: 78px;
    object-fit: cover;
    border-radius: 4px;

    cursor: pointer;
}

#Product-Visual .container-coulissant{
    display: flex;
    align-items: center;
    position: relative;
}

#Product-Visual .container-coulissant > svg{
    position: absolute;
    z-index: 100;
    cursor: pointer;
}

#Product-Visual .container-coulissant .left-svg{
    display: none;
    left: 16px;
    transform: rotate(180deg);
}

#Product-Visual .container-coulissant .right-svg{
    right: 16px;
}

#Product-Visual .container-coulissant > span{
    display: block;
    position: absolute;
    width: 90px;
    height: 100%;
}


#Product-Visual .container-coulissant .left-shadow{
    display: none;
    left: 0;
    background: linear-gradient(-90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.60) 100%);
}


#Product-Visual .container-coulissant .right-shadow{
    right: 0;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.60) 100%);
}

#Product-Visual #Image-Suggest{
    display: flex;
    gap: 16px;
}



#Container-Selection{
    display: flex;
    flex-direction: column;
    width: 25%;
}


#Sticky-Selection{
    background-color: #f9eded;
    border-radius: 8px;
    gap: 24px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-width: 250px;
    padding: 16px;
    position: -webkit-sticky;
    position: sticky;
    top: 140px;
}

#Sticky-Selection, #Sticky-Selection div{
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;

}

#Sticky-Selection div > p{
    color: var(--Rouge-Renau, #D30000);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}


#Sticky-Selection ul{
    display: flex;
    gap: 8px;
    margin-top: 16px;
    width: 100%;
}

#Sticky-Selection li{
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 8px 0px;
    background: white;

    border-radius: 4px;

    cursor: pointer;

}

#Sticky-Selection li.active{
    background: var(--Renau);
    color: white;
}

#Sticky-Selection ul p{
    pointer-events: none;
}









#Conveyor-Option{
    width: 100%;
    max-width: 866px;
}

#Conveyor-Option .Wrapper{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

#Conveyor-Option .Wrapper li{
    display: flex;
    justify-content: center;

    position: relative;


    width: calc(33.3333333% - 14px);

    overflow: hidden;
    border-radius: 4px;
}

#Conveyor-Option .Wrapper p{
    position: absolute;
    color: #FFF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
text-align: center;
    bottom: 16px;
    width: 100%;
}

#Conveyor-Option .Wrapper img{
    width: 100%;
}


#Container-technique ul li{
    display: flex;
    justify-content: space-between;
    padding: 16px 0px;
    border-bottom: solid 1px rgba(0, 0, 0, 0.1);
}

#Container-technique ul li img, #Container-technique ul li span{
    display: block;
    max-width: 124px;
    border-radius: 4px;
    width: 100%;
    
}

#Container-technique ul li p:nth-child(1){
    color: var(--Renau);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}


#Container-technique ul li p:nth-child(2){
    text-align: left;
    width: 100%;
    max-width: 400px;
}





#ListFamille h4{
    font-size: 24px;
    color: #D30000;
}

#ListFamille h4{
    font-size: 20px;
}


.custom-select{
    display: flex;
    flex-direction: column;
    gap: 0px!important;

}

.selected-option{
    display: flex;
    gap: 4px;
    padding: 4px 8px;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}


.remove{
    cursor: pointer;
}

.selected-options{
    display: flex;
    flex-wrap: wrap;
    border-radius: 8px;
    border: 1px solid #161616;
    padding: 16px 24px;
    gap: 4px!important;
    background: white;
    z-index: 1;
}

.selected-options .placeholder{
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    align-items: center;
}

.custom-select select{
    margin-top: -6px;
    border-top: 0px!important;
    border-radius: 0px 0px 8px 8px!important;
}

#Tension, #Motrice{
    flex-direction: row!important;
}




















#Container-technique table{
    border-collapse: collapse;
    width: 100%;
    max-width: 600px;
}

#Container-technique tbody tr:nth-child(1),
#Container-technique tbody tr:nth-child(3),
#Container-technique tbody tr:nth-child(5),
#Container-technique tbody tr:nth-child(7){
    background: rgba(0, 0, 0, 0.05);
}


#Container-technique td{
    text-transform: lowercase;
    padding: 4px 12px;
}

#Container-technique td::first-letter{
  text-transform: capitalize;
}


#Merci-Devis{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 124px 0px;
}

#Merci-Devis img{
    width: 124px;
}

#Merci-Devis .red{
    color: var(--Renau);
    margin-top: 32px;
}

#Merci-Devis div{
    margin-top: 40px;
    display: flex;
    gap: 24px;
}

#Merci-Devis p{
    color: #161616;
text-align: center;
font-family: Inter;
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: normal;
max-width: 750px;
margin-top: 16px;
}


.placeholder-asterisk::after {
    content: "*";
    color: red; /* Adjust the color as needed */
  }

  .container_info_fiche{
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: flex-end;
    width: 100%;
  }





  @media (max-width: 1320px) {
    .container{
        width: 1000px;
    }
    
    #HomeNumber ul{
        flex-wrap: wrap;
        
    }

    #HomeNumber li{
        flex: 1 1 33%;
    }

    .container-argu{
        width: calc(50% - 20px);
    }

    .FooterLink{
        gap: 64px;
    }

    #ListFamille .card{
        width: calc(33.3333333% - 14px);
    }

    #Container-technique ul li{
        flex-direction: column;
        gap: 16px;
    }

    #Container-technique table{
        max-width: none;
    }
}

@media (max-width: 1128px) {
    .container{
        width: 900px;
    }

    #MainHeader nav ul{
        display: none!important;
    }
    
    .NavFlag-responsive{
        display: flex!important;
    }

    .burger{
        display: block;
    }

    .responsive-menu{
        display: flex!important;
    }

    .CopyrightDroit{
        flex-direction: column-reverse;
        align-items: center;
        gap: 16px;
    }

    .CopyrightDroit p, .CopyrightDroit ul{
        width: fit-content;
    }
}

@media (max-width: 924px) {
    .container{
        width: 796px;
    }

    #Actualite .card{
        width: calc(50% - 32px);
    }

    h1, h2, h3{
        text-align: center;
    }

    #HeroBanner > div.container > a{
        display: flex;
        width: fit-content;
        margin: auto;
    }


    .FooterCoorLink{
        flex-direction: column;
    }

    .FooterLink{
        margin-top: 56px;
        justify-content: space-between;
    }

    .FooterLink ul{
        width: fit-content;
    }

    #InfoContact{
        margin-top: 32px;
    }


    .FooterCoor #InfoContact{
        width: 100%;
    }

    .FooterCoor{
        align-items: center;
    }

    .FooterCoor ul{
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .FooterCoor ul li{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    
    #CompagnyValue .Wrapper div{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: calc(50% - 16px);
    }

    #CompagnyValue .Descriptif{
        text-align: center;
    }

    #CompagnyAbout .Information > div{
        width: 100%;
    }

    #CompagnyAbout .Information{
        flex-direction: column-reverse;
    }

    #CompagnyAbout .Information .Carrousel{
        aspect-ratio: 1/1;
    }

    #FamilleHeroBanner p{
        text-align: center;
    }

    #FamilleHeroBanner > div > div:nth-child(2){
        display: flex;
        flex-direction: column;
        align-items: center;

    }

    #Article{
        flex-direction: column;
    }

    #Article .contenu{
        width: 100%;
    }

    #Article ul{
        display: flex;
        flex-wrap: wrap;
        gap: 32px;
        justify-content: center;

    }

    #Article ul li{
        width: fit-content;
    }

    #root > footer > div.FooterCoorLink > div.FooterCoor > a.mt-32{
        text-align: center;
    }



    
    
}

@media (max-width: 860px) {
    .container{
        width: 738px;
    }

    #ListFamille .card{
        width: calc(50% - 10px);
    }

    
}

@media (max-width: 768px) {
    .container{
        width: 644px;
    }

    .temoignage{
        flex-direction: column;
    }
    .temoignage div{
        width: 100%;
    }

    #Temoignage .container-content{
        align-items: center;
        margin-top: 16px;
    }

    #Temoignage .container-content .Avis{
        text-align: center;
    }

    #Temoignage .ContainerTop{
        height: 383px;
    }

    #Temoignage .Menu{
        margin-top: 32px;
    }

    #Temoignage .coulissant{
        align-items: center;
    }

    #Technical-Conveyor{
        flex-direction: column-reverse;
    }

    #Container-Selection{
        width: 100%;
    }

    #Sticky-Selection{
        flex-direction: unset;
        min-width: auto;
        width: 100%;
        flex-wrap: wrap;
        margin-top: 32px!important;
        align-items: self-start;
    }

    #Sticky-Selection div{
        width: calc(50% - 12px);
    }

    #CompagnyNumber .Wrapper .NumberContainer{
        flex-wrap: wrap;
        justify-content: space-around;
        gap: 24px;
    }

    #FamilleHeroBanner a{
        display: flex;
        justify-content: center;

    }


    #Articles .Wrapper a{
        width: 100%;
        align-items: center;
    }

    #EssentialInfo input{
        width: calc(50% - 32px);
    }

    #EssentialInfo > p, 
    #ProductInfo > p,
    #ConveyorInfo > p,
    #CommentForm > p  {
        text-align: center;
    }   

    .ProductForm{
        flex-direction: column;
    }

    .PreviewConveyor{
        width: 100%!important;
        aspect-ratio: 1/1;
    }

    .Productanwser,
    .containerdocuments{
        width: 100%!important;
    }

    .Productanwser button{
        margin: auto;
    }

    #InfoContact{
        flex-direction: column;
    }

    #ContactForm button{
        margin: 20px auto 0px auto;
    }

    #CommentForm button{
        margin-left: auto;
        margin-right: auto;
    }

    #CommentForm > div{
        justify-content: center!important;
    }

    .PreviewConveyor img{
        height: 100%;
    }

    .ContainerProduit{
        flex-direction: column;
    }

    #FormProduit .ContainerProduit .information{
        width: 100%;
    }

}

@media (max-width: 704px) {
    .container{
        width: 576px;
    }

    #SavoirFaire > div:nth-child(2), #SavoirFaire > div:nth-child(4){
        flex-direction: column;
    }

    #SavoirFaire > div:nth-child(3), #SavoirFaire > div:nth-child(5){
        flex-direction: column-reverse;
    }

    #SavoirFaire .horizontale{
        width: 100%;
    }

    #SavoirFaire .horizontale > div{
        width: 100%;
    }

    #Conveyor-Option .Wrapper li{
        width: calc(50% - 10px);
    }

    .FooterCoor ul{
        flex-wrap: wrap;
        justify-content: space-evenly;
        gap: 24px;
    }

    
}

@media (max-width: 608px) {
    .container{
        width: 512px;
    }

    #Actualite .card{
        width: 100%;
    }

    #Actualite .cards img{
        object-fit: cover;
    }

    #ListFamille .card{
        width: 100%;
    }

    #CompagnyValue .Wrapper div{
        width: 100%;
    }


}

@media (max-height: 750px) {
    #FamilleHeroBanner{
        height: auto;
        padding: 96px 0px;
    }

    #FamilleHeroBanner.compagny{
        min-height: 100vh;
    }
}  

@media (max-height: 630px) {
    #FamilleHeroBanner.compagny{
        height: auto;
    }

}


@media (max-height: 544px) {

    .containerslogan{
        padding-top: 64px;
        position: initial;
        margin-bottom: 64px;
    }
    
    .containerherobannercontent{
        padding-bottom: 64px;
    }
    }  


@media (max-width: 544px) {
    .container{
        width: 448px;
    }

    .container-argu{
        width: 100%;
        justify-content: center;
    }

    .CopyrightDroit ul{
        flex-direction: column;
        align-items: center;
    }

    h1{
        font-size: 40px;
        line-height: 48px;
    }

    .containerslogan{
        padding-top: 64px;
        position: initial;
        margin-bottom: 64px;
    }


    .containerherobannercontent{
        padding-bottom: 64px;
    }

    .containerslogan p{
        font-size: 32px;
        line-height: 40px;
    }

    #HomeNumber ul{
        flex-direction: column;
    }

    #HomeNumber ul span{
        rotate: 90deg;
    }

    #HomeNumber li{
        width: 100%;
        justify-content: center;
        flex-direction: column;
        gap: 0px;
    }

    .NumberText{
        max-width: fit-content;
    }

    #Newsletter p, #Newsletter div{
        max-width: 448px;
    }



    .CopyrightDroit p{
        text-align: center;
        width: 80%;
    }
}

@media (max-width: 480px) {
    .container, #Newsletter p, #Newsletter div{
        width: 400px;
    }

    .container-argu{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .Argumentation{
        text-align: center;
    }

    .containerslogan{
        flex-direction: column;
    }

    h1{
        font-size: 32px;
        line-height: normal;
    }



    .containerslogan{
        gap: 4px;
    }

    #Articles .Wrapper a{
        flex-direction: column;
    }

    #Articles .Wrapper a .card-content{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    #Articles .Wrapper a .card-description{
        text-align: center;
        width: 90%;
    }

    .Productanwser div, #EssentialInfo div{
        flex-direction: column;
    }

    #EssentialInfo div, #EssentialInfo div input{
        width: 100%;
    }

    #Temoignage .ContainerTop{
        height: 500px;
    }
}

@media (max-width: 425px) {
    .container, #Newsletter p, #Newsletter div{
        width: 393px;
    }

    .FooterLink{
        align-items: center;
        flex-direction: column;
        gap: 32px;
    }

    #CompagnyNumber .Wrapper div{
        width: 100%;
    }

    .Information a{
        display: flex;
        justify-content: center;
    }



    .FooterCoor{
        align-items: center;
    }

    .FooterLink ul{
        align-items: center;
    }

    #ContactForm div{
        flex-direction: column;
    }

    #Sticky-Selection{
        flex-direction: column;
    }

    #Sticky-Selection div{
        width: 100%;
    }


}

@media (max-width: 375px) {
    .container, #Newsletter p, #Newsletter div{
        width: 351px;
    }

}


@media (max-width: 375px) {
    .container, #Newsletter p, #Newsletter div{
        width: 351px;
    }

}

@media (max-width: 320px) {
    .container, #Newsletter p, #Newsletter div{
        width: 304px;
    }

    #Articles .card-title{
        text-align: center;
    }

}



#fiche-technique .cards{
    display: flex;
    gap: 16px;
    
}

#fiche-technique .card{


    display: flex;
    align-items: center;
    gap: 8px;


    padding: 8px 16px;

    cursor: pointer;
    background: #D30000;
    
    border-radius: 100px;


}

#fiche-technique .card img{
    height: 20px;
}

#fiche-technique .card p{
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #fff;
}


#FamilleHeroBanner .cards{

    display: flex;
    gap: 16px;

    margin-top: 40px;

}

#FamilleHeroBanner .card{
    padding: 8px 18px;
    display: flex;
    gap: 8px;
    font-size: 16px;
    border: solid 1px white;
    background: #D30000;

    border-radius: 100px;
}

#FamilleHeroBanner .card img{
    position: unset;
    z-index: 1;
    width: 20px;
    height: 20px;
}

#FamilleHeroBanner .card p{
    color: white;
}


.mt-96{
    margin-top: 96px;
}