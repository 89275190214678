#Temoignage{
    display: flex;
    flex-direction: column;
    align-items: center;
}

#Temoignage img{
    max-height: 170px;
    max-width: 320px;
    object-fit: cover;
}

#Temoignage .ContainerTop{
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
    height: 300px;
    width: 100%;
}

#Temoignage .coulissant{
    display: flex;
    position: absolute;
    width: fit-content;

    transition: all ease-in-out 400ms;
}

.temoignage{
    display: flex;
    align-items: center;
}

.temoignage div{
    width: 50%;
}


.ContainerTop .containerIMG{
    display: flex;
    justify-content: center;
    align-items: center;
}

#Temoignage .container-content{
    display: flex;
    flex-direction: column;
}

#Temoignage .container-content .Poste{
    color: #161616;
    font-family: Inter;
    font-size: 16px;
    font-style: italic;
    font-weight: 500;
    line-height: normal;
}

#Temoignage .container-content .Nom{
    color: #161616;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

#Temoignage .container-content .Avis{
    color: #161616;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
}