.red-circle{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    background: var(--Renau);
    border-radius: 100px;

    color: #FFF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.Argument{
    color: #161616;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 125% */
}

.Argumentation{
    color: #161616;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
}

.container-argu{
    min-width: 200px;
    width: calc(33.33% - 27px);
}

.container-argus{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 40px;
}