#FamilleHeroBanner{
    display: flex;
    align-items: center;
    height: 50vh;
    width: 100vw;
    overflow: hidden;
    position: relative;

}

#FamilleHeroBanner img{
    position: absolute;
    width: 100vw;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}

#FamilleHeroBanner p{
    color: #FFF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */

    max-width: 580px;
}

.navigation{
    display: flex;
    position: absolute;
    top: 55px;
    gap: 8px;
    
}

.navigation a, .navigation p{
    color: #FFF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}