#Client .cards{
 display: flex;
 gap: 32px;
 width: fit-content;
}

#Client .card{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    min-width: 272px;
    height: 200px;
    border-radius: 16px;
    background: #FFF;
    box-shadow: 0px 0px 40px -6px rgba(0, 0, 0, 0.13);
}

#Client .card > img{
    max-width: 124px;
    max-height: 64px;
}

#Client .card .Secteur{
    display: flex;
    align-items: center;
    position: absolute;
    right: 16px;
    bottom: 8px;
    gap: 4px;
}

#Client .transform{
    transform: translateX(-152px);
}

#Client .card .Secteur img{
    width: 12px;
    height: 12px;
}


#Client .card .Secteur p{
    color: #161616;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
}