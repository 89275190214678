#DevisAsk{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--Red10);
    width: 100vw;
    height: 40vh;
}

#DevisAsk p {
    color: #161616;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */

    max-width: 580px;
}