#SavoirFaire .horizontale{
    display: flex;
}

#SavoirFaire .horizontale > div{
    width: 50%;
}

#SavoirFaire .horizontale div img{
    width: -webkit-fill-available;
    height: 100%;
    max-height: 300px;
    object-fit: cover;
}


#SavoirFaire .horizontale .content{
    padding: 24px;
}

#SavoirFaire .horizontale .content div{
    display: flex;
    align-items: center;
    gap: 16px;
}

#SavoirFaire .horizontale .content .number{
    color: #D30000;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -1.28px;
}

#SavoirFaire .horizontale .content .title{
    color: #000;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.content > p {
    color: #161616;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
}