#IndexHeader{
    display: flex;
    justify-content: center;
    width: 100vw;
    height: 100px;
    position: absolute;
}

#IndexHeader nav{
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 64px;
}

#IndexHeader nav ul{
    display: flex;
    align-items: center;
    gap: 32px;
}

#IndexHeader nav a{
    color: white;
}




#MainHeader{
    display: flex;
    justify-content: center;
    width: 100vw;
    height: 100px;
    z-index: 1000;
}

#MainHeader nav{
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    width: 100vw;
    height: 100px;
    border-bottom: 1px solid rgba(22, 22, 22, 0.05);
    background: #FFF;
    box-shadow: 0px 0px 40px -6px rgba(0, 0, 0, 0.25);
    z-index: 1000;
}

#MainHeader nav div{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#MainHeader nav ul{
    display: flex;
    align-items: center;
    gap: 32px;
}

#MainHeader nav a{
    display: flex;
    color: black;
}



.NavFlag-responsive{
    display: none!important;
}


.responsive-menu{
    display: none!important;
    gap: 16px;
}


.responsive-menu .burger{
    cursor: pointer;
    z-index: 10000;
}


.responsive-nav{
    position: fixed;
    right: 0;
    display: flex;
    flex-direction: column;
    width: 100vw;
    top: 100px;
    height: calc(100vh - 100px);
    background-color: white;
    z-index: 1000;
    transition: all ease-in-out 500ms;

}

.responsive-nav ul{
    width: 100;
    height: 100%;
}

.responsive-nav li{
    display: flex;

    width: 100%;
    height: 20%;
    background-color: white;

    cursor: pointer;

    transition: all ease-in 300ms;
}

.responsive-nav li a{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.responsive-nav li:hover{
    background-color: #D30000;
}

.responsive-nav li:hover a{
    transition: all ease-in 300ms;
    color: white;
}



.burger{
    display: none;
}