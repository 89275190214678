#HeroBanner{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;

    position: relative;

    
}

#HeroBanner video{
    position: absolute;
    min-height: -webkit-fill-available;
    width: -webkit-fill-available;
    z-index: -1;
    object-fit: cover;
}