#HomeNumber{
    background-color: var(--Renau);
    padding: 50px 0px;
}

#HomeNumber ul{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#HomeNumber li{
    display: flex;
    align-items: center;
    gap: 20px;
}

#HomeNumber .Number{
        color: #FFF;

    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -2.56px;
}

.NumberText{
    color: #FFF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 125% */

    max-width: 100px;
}

.VerticalLine{
    display: block;
    width: 1px;
    background: white;
    height: 40px;
}